<template>
  <div class="space-y-4">
    <asom-alert v-if="error" variant="error" :error-message="error" />
    <div class="w-full text-center" v-else-if="isLoading">
      <asom-icon icon="spinner" class="animate-spin" />
    </div>
    <template v-else>
      <asom-card>
        <asom-alert variant="warning" v-if="isDeclarationEmpty">
          There are no AFC Store Spare Key Press details for this station yet. 
          Please click on the button "Update" to start a new one.
        </asom-alert>
        <asom-form-field label="Station">
          <span>{{ stationName }}</span>
        </asom-form-field>
        <asom-form-field
          label="Info of Store Spare Key"
          :state="inputStates('formData.afcStoreKeyData')"
          error="Store Spare Key info cannot be empty"
        >
          <div
            id="text-holder"
            class="border border-gray-600 p-4 rounded-md"
            v-if="!updateMode"
            v-dompurify-html="afcStoreKeyData"
          ></div>
          <div class="pt-4" v-else>
            <asom-input-text-editor v-model="formData.afcStoreKeyData" />
          </div>
        </asom-form-field>
        <div class="col-span-2 flex justify-end space-x-4 pt-8">
          <asom-button
            v-if="updateMode"
            variant="secondary"
            text="Cancel Update"
            @click="updateMode = false"
          />
          <asom-button
            v-if="!updateMode"
            text="Update"
            @click="startUpdateMode"
          />
          <asom-button
            v-else
            text="Confirm Update"
            @click="update"
            :loading="isSubmitting"
            :disabled="isSubmitting"
          />
        </div>
      </asom-card>
    </template>
  </div>
</template>

<script>
import get from "lodash.get";
import isEmpty from "lodash.isempty";
import { required } from "@vuelidate/validators";
import { mapGetters } from "vuex";
import inputStates from "@/mixins/inputStates";
import { useVuelidate } from "@vuelidate/core";
import {
  GET_AFC_STORE_SPARE_KEY_DETAILS,
  UPDATE_AFC_STORE_SPARE_KEY,
} from "@/constants/apis";
import { getAsync, postAsync } from "@/services/serviceHandlers";

export default {
  setup: () => ({ v$: useVuelidate() }),
  mixins: [inputStates],
  data() {
    return {
      isLoading: false,
      isSubmitting: false,
      error: null,
      afcStoreKeyData: null,
      formData: {
        afcStoreKeyData: null,
      },
      updateMode: false,
    };
  },
  validations() {
    return {
      formData: {
        afcStoreKeyData: { required },
      },
    };
  },
  mounted() {
    this.loadData();
  },
  computed: {
    ...mapGetters({
      stationId: "selectedStation/id",
      lineId: "auth/userLineId",
      stationName: "selectedStation/name",
    }),
    isDeclarationEmpty() {
      return isEmpty(this.afcStoreKeyData);
    },
  },
  methods: {
    async loadData() {
      this.isLoading = true;
      this.error = null;
      const response = await getAsync({
        url: GET_AFC_STORE_SPARE_KEY_DETAILS,
        params: {
          stationId: this.stationId,
          lineId: this.lineId,
        },
      });
      if (response.success) {
        this.afcStoreKeyData = get(response, "payload.data");
      } else {
        this.error = response.payload;
      }
      this.isLoading = false;
    },
    update() {
      if (this.isSubmitting) return;
      this.error = null;
      this.v$.$reset();
      this.v$.$touch();
      if (!this.v$.$invalid) {
        this.onSubmit();
      } else {
        this.error = "Please complete all required fields";
        this.$scrollTop();
      }
    },
    async onSubmit() {
      this.isSubmitting = true;
      const result = await postAsync({
        url: UPDATE_AFC_STORE_SPARE_KEY,
        body: {
          data: this.formData.afcStoreKeyData,
          stationId: this.stationId,
          lineId: this.lineId,
        },
      });
      if (result.success) {
        this.isSubmitting = false;
        this.updateMode = false;
        this.loadData();
      } else {
        this.isSubmitting = false;
        this.error = result.payload;
        this.$scrollTop();
      }
    },
    startUpdateMode() {
      this.updateMode = true;
      this.formData.afcStoreKeyData = this.afcStoreKeyData || "";
    },
  },
};
</script>

<style></style>
